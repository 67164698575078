export default function sendMail({
  templateName,
  sendTo,
  subject,
  isHtml = true,
  cc = "",
  bcc = "",
  templateData = "",
}) {
  if (process.env.GATSBY_ENV) {
    subject = `${subject} - ${process.env.GATSBY_ENV.replace("-", "").trim()}`;
  }
  let data = {
    templateName: templateName,
    templateData: templateData,
    moduleName: "campaign-aggregator",
    cc: cc,
    bcc: bcc,
    sendTo: sendTo,
    subject: subject,
    isHtml: isHtml,
  };
  var raw = JSON.stringify(data);
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        process.env.GATSBY_MAIL_ENGINE_USERNAME +
          ":" +
          process.env.GATSBY_MAIL_ENGINE_PASSWORD
      )}`,
    },
    body: raw,
    redirect: "follow",
  };
  fetch(
    `https://prod-mettasocial-email-engine.herokuapp.com/sendMail`,
    requestOptions
  )
    .then(resposne => resposne.text())
    .catch(error => {
      throw error;
    });
}

export function sendMailWithAttachment({
  templateName,
  sendTo,
  subject,
  isHtml = true,
  cc = "",
  bcc = "",
  templateData = "",
  attachmentData = "",
}) {
  if (process.env.GATSBY_ENV) {
    subject = `${subject} - ${process.env.GATSBY_ENV.replace("-", "").trim()}`;
  }
  let data = {
    templateName: templateName,
    templateData: templateData,
    attachmentData: attachmentData,
    moduleName: "campaign-aggregator",
    cc: cc,
    bcc: bcc,
    sendTo: sendTo,
    subject: subject,
    isHtml: isHtml,
  };
  var raw = JSON.stringify(data);
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        process.env.GATSBY_MAIL_ENGINE_USERNAME +
          ":" +
          process.env.GATSBY_MAIL_ENGINE_PASSWORD
      )}`,
    },
    body: raw,
    redirect: "follow",
  };
  fetch(
    `https://prod-mettasocial-email-engine.herokuapp.com/sendMailWithAttachment`,
    requestOptions
  )
    .then(resposne => resposne.text())
    .catch(error => {
      throw error;
    });
}
