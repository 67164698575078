import React, { useContext } from "react";
import sendMail from "../helpers/emailSender";
import { showToast } from "../store/actions";
import { StateContext } from "../store/context";
import styles from "./contactUs.module.scss";
import MobileNumberInput from "./mobileNumberInput";

const ContactUsSection = () => {
  const { dispatch } = useContext(StateContext);
  const handleSubmit = async e => {
    let json = {};
    e.persist();
    e.preventDefault();
    new FormData(e.target).forEach((value, key) => {
      json[key] = value;
    });

    const data = json;
    sendMail({
      templateName: "contactUs",
      sendTo: "contactus@mettasocial.com",
      subject: "New query raised",
      moduleName: "campaign-aggregator",
      templateData: data,
    });
    showToast(dispatch, "Query submitted Successfully", "success");
    e.target.reset();
  };

  return (
    <section className={`${styles.ContactUsSection} w-100`}>
      <div className={`container ${styles.ContactUsContainer}`}>
        <span className="pill-label pill-bg-white">Contact Us</span>
        <h3 className="section-title text-white text-capitalize">
          For any queries or donations above 5 lacs INR
        </h3>
        <form onSubmit={handleSubmit} className="contactForm">
          <div className="row p-0">
            <div className={`col-12 col-lg-4 form-group ${styles.formGroup}`}>
              <input
                className="form-control"
                id="name"
                name="name"
                type="text"
                placeholder="Full Name"
                minLength="2"
                required
              ></input>
            </div>
            <div className={`col-12 col-lg-4 form-group ${styles.formGroup}`}>
              <input
                className="form-control"
                id="email"
                name="email"
                type="email"
                placeholder="Email Address"
                minLength="2"
                required
              ></input>
            </div>
            <div
              className={`col-12 col-lg-4 form-group contactField ${styles.formGroup}`}
            >
              <MobileNumberInput name="mobileNumber" onChangeFunc={false} />
            </div>
            <div
              className={`col-12 form-group ${styles.formGroup} mt-0 mt-lg-3 mb-4 mb-md-3`}
            >
              <textarea
                className="form-control"
                id="query"
                name="query"
                type="text"
                placeholder="Message"
                rows={3}
                minLength="20"
                required
              ></textarea>
            </div>
            <div className={`col-12 mt-0 mt-md-3 text-center`}>
              <button
                type="submit"
                className={`btn button-8BC540 btn-custom font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase`}
              >
                <span className={`mx-3 h5 mb-0 font-16 text-uppercase`}>
                  Submit
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactUsSection;
