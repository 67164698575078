import { useEffect, useLayoutEffect, useState } from "react";
import { useQuery } from "react-query";

export function useGetCampaignDonationData(sortKey, slug) {
  const [donationData, setDonationData] = useState([]);
  const { data: response = {} } = useQuery(
    `${slug}-donationData`,
    async () => {
      const res = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/getPaymentsByCampaignId?campaignId=${slug}`
      );
      const json = await res.json();
      return json;
    },
    {
      refetchInterval: 15000,
    }
  );
  useEffect(() => {
    if (!response.campaignPayments) {
      return;
    }
    const sortedData = response.campaignPayments
      .map(x => ({
        ...x,
        amount: x.amount / 100,
        actualAmount: x.actualAmount / 100,
      }))
      .sort((a, b) => {
        return a[sortKey] > b[sortKey] ? -1 : 1;
      });
    setDonationData({
      campaignPayments: sortedData,
    });
  }, [slug, sortKey, response]);
  return donationData;
}

export function useIntersectionObserver({
  target,
  threshold,
  rootMargin = "0px 0px 0px 0px",
}) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const callback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.target === document.querySelector(target)) {
        if (entry.intersectionRatio >= threshold) {
          setIsIntersecting(true);
        } else {
          setIsIntersecting(false);
        }
      }
    });
  };
  useLayoutEffect(() => {
    let options = {
      threshold: [0, threshold],
      rootMargin,
    };
    let observer = new IntersectionObserver(callback, options);
    observer.observe(document.querySelector(target));
  });

  return isIntersecting;
}

export function useGetFundRaisedByCampaign(campaignId) {
  return useQuery(`${campaignId}-fundraised`, async () => {
    const response = await fetch(
      `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/getFundRaisedByCampaignId?campaignId=${campaignId}`
    );
    const json = await response.json();
    const fundRaised = json.fundRaised / 100;
    const donorsCount = json.donorsCount || 0;
    return {
      fundRaised,
      donorsCount,
    };
  });
}

export function useGetCountryList() {
  return useQuery(
    `global-country-list`,
    async () => {
      const response = await fetch(`https://countriesnow.space/api/v0.1/countries`);
      const parsedData = await response.json();
      const data = parsedData.data.map(x => x.country);
      return data;
    },
    {
      cacheTime: Infinity,
    }
  );
}
export function useLeaderboardList(campaignId) {
  return useQuery(`leaderboard-list`, async () => {
    const response = await fetch(
      `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/getLeaderBoardbyCampaignId?campaignName=/campaign/${campaignId}`
    );
    return response.json();
  });
}

export function useGetPaymentsByChildCampaignId(
  sortKey,
  slug,
  childCampaignId
) {
  const [donationData, setDonationData] = useState([]);
  const { data: response = {} } = useQuery(
    `${slug}&childCampaignId=${childCampaignId}-donationData`,
    async () => {
      const res = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/getPaymentsByChildCampaign?campaignId=${slug}&childCampaignId=${childCampaignId}`
      );
      const json = await res.json();
      return json;
    },
    {
      refetchInterval: 15000,
    }
  );
  useEffect(() => {
    if (!response.campaignPayments) {
      return;
    }
    const sortedData = response.campaignPayments
      .map(x => ({
        ...x,
        amount: x.amount / 100,
        actualAmount: x.actualAmount / 100,
      }))
      .sort((a, b) => {
        return a[sortKey] > b[sortKey] ? -1 : 1;
      });
    setDonationData({
      campaignPayments: sortedData,
    });
  }, [slug, sortKey, response]);
  return donationData;
}

export function useGetFundRaisedByChildCampaign(campaignId, childCampaignId) {
  return useQuery(
    `${campaignId}&childCampaignId=${childCampaignId}-fundraised`,
    async () => {
      const response = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/getFundRaisedByChildCampaign?campaignId=${campaignId}&childCampaignId=${childCampaignId}`
      );
      const json = await response.json();
      const fundRaised = json.fundRaised / 100;
      const donorsCount = json.donorsCount || 0;
      return {
        fundRaised,
        donorsCount,
      };
    }
  );
}
